import Cookies from "js-cookie";

const cartCookieName = "bookstore-cart";
const isLiveOrderCookieName = "is-live-order";
const shippingMethodCookieName = "shipping-method";

function getCart() {
	return JSON.parse(Cookies.get(cartCookieName) || "[]");
}

export function clearCart() {
	Cookies.set(cartCookieName, JSON.stringify([]), { expires: 2 });
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function addProductToCart(product: { id: any }) {
	console.info("add product to cart", product);
	const cart = getCart();
	let added = false;
	for (const element of cart) {
		if (element.id === product.id) {
			element.count += 1;
			added = true;
		}
	}
	if (!added) {
		cart.push(product);
	}
	Cookies.set(cartCookieName, JSON.stringify(cart), { expires: 2 });
	updateCartPreview();
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
function getCartPreviewHtml(cartItem: { title: any; count: any }) {
	return `<div>${cartItem.title} => ${cartItem.count}</div>`;
}

function getCartItemHtml(cartItem: {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	title: any;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	author: any;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	count: any;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	price: any;
}) {
	return `<div>${cartItem.title} by ${cartItem.author}   ${cartItem.count} x ${cartItem.price}</div>`;
}

export function updateCartPreview() {
	const cartPreview = document.getElementById("cart-preview");
	if (!cartPreview) {
		return;
	}
	const cart = getCart();
	const carItemsCount = cart.reduce(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		(acc: any, item: { count: any }) => acc + item.count,
		0,
	);
	if (carItemsCount < 1) {
		// @ts-ignore
		document.getElementById("cart-count").textContent = "";
		cartPreview.innerHTML = "<p>Cart is empty</p>";
	} else {
		// @ts-ignore
		document.getElementById("cart-count").textContent = `(${carItemsCount})`;
		cartPreview.innerHTML = cart
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			.map((item: { title: any; count: any }) => getCartPreviewHtml(item))
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			.reduce((acc: any, item: any) => acc + item, "");
	}
}

export function fillCart() {
	const cartEl = document.getElementById("cart");
	if (!cartEl) {
		return;
	}
	const cart = getCart();
	const carItemsCount = cart.reduce(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		(acc: any, item: { count: any }) => acc + item.count,
		0,
	);
	if (carItemsCount < 1) {
		cartEl.innerHTML = "<p>Cart is empty</p>";
		const button = document.getElementById("purchase-btn");
		// @ts-ignore
		button.disabled = true;
	} else {
		cartEl.innerHTML = cart
			.map(
				(item: {
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					title: any;
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					author: any;
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					count: any;
					// biome-ignore lint/suspicious/noExplicitAny: <explanation>
					price: any;
				}) => getCartItemHtml(item),
			)
			// biome-ignore lint/suspicious/noExplicitAny: <explanation>
			.reduce((acc: any, item: any) => acc + item, "");
	}
}

export function getIsLiveOrder() {
	// default false
	return Cookies.get(isLiveOrderCookieName) === "true";
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function setIsLiveOrder(isLiveOrder: any) {
	Cookies.set(isLiveOrderCookieName, isLiveOrder ? "true" : "false");
}

export function getShippingMethod() {
	return Cookies.get(shippingMethodCookieName) ?? "default";
}

export function setShippingMethod(shippingMethod: string) {
	Cookies.set(shippingMethodCookieName, shippingMethod);
}

export function resetCart() {
	clearCart();
	fillCart();
	updateCartPreview();
}

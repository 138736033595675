import "htmx.org/dist/htmx.esm";

import {
	addProductToCart,
	fillCart,
	getIsLiveOrder,
	getShippingMethod,
	resetCart,
	setIsLiveOrder,
	setShippingMethod,
	updateCartPreview,
} from "./cart";
import { initializeCheckout } from "./checkout";
import { sendEvent } from "./event";
import { validateCheckoutReturn } from "./return";

window.addProductToCart = addProductToCart;
window.getIsLiveOrder = getIsLiveOrder;
window.getShippingMethod = getShippingMethod;
window.fillCart = fillCart;
window.initializeCheckout = initializeCheckout;
window.resetCart = resetCart;
window.sendEvent = sendEvent;
window.setIsLiveOrder = setIsLiveOrder;
window.setShippingMethod = setShippingMethod;
window.updateCartPreview = updateCartPreview;
window.validateCheckoutReturn = validateCheckoutReturn;

import Cookies from "js-cookie";

export async function sendEvent(
	eventType: string,
	version: string,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	payload: any,
) {
	// biome-ignore lint/style/noNonNullAssertion: <explanation>
	const csrfToken = Cookies.get("csrftoken")!;

	const data = {
		eventType,
		version,
		payload,
	};

	await fetch("/send_event", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"X-CSRFToken": csrfToken,
		},
		body: JSON.stringify(data),
	});
}

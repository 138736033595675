import { clearCart } from "./cart";

export async function validateCheckoutReturn() {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const sessionId = urlParams.get("session_id");
	const response = await fetch(`/session-status?session_id=${sessionId}`);
	const session = await response.json();

	if (session.status === "open") {
		// window.replace('http://localhost:4242/checkout.html')
		console.log("SESSION NOT FINISHED");
	} else if (session.status === "complete") {
		// @ts-ignore
		document.getElementById("success").classList.remove("hidden");
		// @ts-ignore
		document.getElementById("customer-email").textContent =
			session.customer_email;
		clearCart();
	}
}
